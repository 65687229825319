// 校友
<template>
  <div class="role">
    <role-page-layout>
      <div class="container students parents">
        <div class="div_mod div_mod1 div_mod1">
          <div class="div_text" v-html="bz">
          </div>
        </div>
        <div
          style="margin-top: 0.4rem"
          v-for="(block, index) in blocks"
          :key="block.id"
        >
          <div class="div_tit">
            <h2>{{ block.title }}</h2>
          </div>
            <!-- 栏目简介 -->
          <div v-html=" block.bz">
          </div>

          <div class="news" v-if="block.code === 'stories'">
            <div class="lunbo article-lunbo">
              <div class="swiper-container">
                <ul class="swiper-wrapper">
                  <li
                    class="swiper-slide"
                    v-for="article in blockArticles[index]"
                    :key="article.id"
                  >
                    <ArticleCard :article="article" />
                  </li>
                </ul>
              </div>
              <div class="direction">
                <span class="s_prev"><i></i></span>
                <span class="s_next"><i></i></span>
              </div>
            </div>
          </div>

          <div v-else-if="block.code === 'pics'">
            <div class="lunbo">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="article in blockArticles[index]"
                    :key="article.id"
                  >
                    <div class="imgbox">
                      <img :src="article.files" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="direction">
                <span class="s_prev"><i></i></span>
                <span class="s_next"><i></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </role-page-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RolePageLayout from './components/RolePageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import BlockLink from './components/BlockLink.vue'
import BlockLink2 from './components/BlockLink2.vue'
import BlockQRcode from './components/BlockQRcode.vue'
import BlockCover from './components/BlockCover.vue'
import ArticleCard from './components/ArticleCard.vue'
import portalApi from '../../../lvya-common/src/api/eos/portal'
export default {
  components: {
    RolePageLayout,
    BlockLink,
    BlockQRcode,
    BlockLink2,
    BlockCover,
    ArticleCard,
  },
  computed: {
    ...mapState({
      roles: (state) => state.yzsyStore.roles,
    }),
    typeId() {
      return this.roles?.find((item) => item.path === this.$route.path)?.id
    },
    bz() {
      return this.roles?.find((item) => item.path === this.$route.path)?.bz
    }
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
    }
  },
  methods: {
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
      await this.getBlockArticles()
    },
    async getBlockArticles() {
      for (const block of this.blocks) {
        const articles = await this.getArticles(block.id)
        this.blockArticles.push(articles)
      }
    },
    async getArticles(typeId) {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: 1,
        pageSize: 99,
        typeId,
        isContent: 'Y',
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      return resData.list
    },
  },
  async mounted() {
    await this.getBlocks()

    // 校友返校日掠影
    new Swiper('.lunbo .swiper-container', {
      loop: false,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 30,
      navigation: {
        nextEl: '.lunbo .s_next',
        prevEl: '.lunbo .s_prev',
      },

      breakpoints: {
        1024: {
          // 当屏幕宽度大于等于320
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/news.css";

// .article-lunbo {
//   position: relative;
// }
.lunbo.article-lunbo {
  background: transparent;
}
.lunbo ul {
  /deep/ li {
    background: #fff;
    box-shadow: 0px 1px 9.8px 0.2px rgba(0, 0, 0, 0.1);
    &:hover {
      .imgbox img {
        transform: scale(1.1);
      }
    }
    .imgbox {
      overflow: hidden;
      img {
        transform: scale(1);
        transition: transform 0.3s linear;
      }
    }
    .txtbox {
      padding: 0.3rem;
      overflow: hidden;
      text-align: center;
      b {
        display: none;
      }
      h3 {
        text-align: left;
        font-size: 0.24rem;
        color: #333;
        line-height: 1.5;
        margin-bottom: 0.15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 0.16rem;
        color: #555;
        opacity: 0.8;
        line-height: 1.5;
        margin-bottom: 0.15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      span {
        display: block;
        width: 1.3rem;
        height: 0.48rem;
        line-height: 0.46rem;
        text-align: center;
        border: 1px solid #b82e1b;
        font-size: 0.16rem;
        color: #b82e1b;
        border-radius: 10px;
        float: right;
        // position: absolute;
        right: 0.3rem;
        bottom: 0.3rem;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
    .direction span {
        top: 50%;
    }
}
</style>
